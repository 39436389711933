import React, { useState, useRef, useEffect } from "react"
import classNames from "classnames"
import {centerHorizontalNavItem, showElemFullyInScrollContainer} from "../../../utils/showElemFullyInScrollContainer"

import styles from "./styles.module.scss"

import AdaptiveWrapText from "../AdaptiveWrapText"

import FuchsiaBackdropShape from "./assets/FuchsiaBackdropShape"
import CyanBackdropShape from "./assets/CyanBackdropShape"
import PurpleBackdropShape from "./assets/PurpleBackdropShape"
import AdaptiveLink from "../AdaptiveLink"
import ConsultationCtaFormA from "../ConsultationCtaFormA"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";
import mediaContent from "../../../pagesSections/index/MainFeatures/mediaContent.module.scss";

export interface FeatureGalleryItem {
	desktopContentWidth?: number
	tabName: string
	tabNameTablet?: string
	tabNameMobile?: string
	// Заголовки могут быть для разных раскладок
	desktopTitle: string | JSX.Element
	tabletTitle?: string | JSX.Element
	mobileTitle?: string | JSX.Element

	// Текстовый контент может быть для разных
	desktopContent: string | JSX.Element
	tabletContent?: string | JSX.Element
	mobileContent?: string | JSX.Element

	columnStyleDesktop?: string
	columnStyleTablet?: string
	columnStyleMobile?: string

	detailLink?: { href: string; useGatsbyLink: boolean; text?: string; rel?: string } | string
	detailText?: string
	additionalDetailLink?: { href: string; useGatsbyLink: boolean; text?: string; rel?: string } | string
	linkRel?: string
	isPatternHidden?: boolean

	mediaContent: JSX.Element
	backdropContent?: JSX.Element
	bottomContent?: JSX.Element
	tabClassName?: string
}

export type ColorTheme =
	| "fuchsia"
	| "fuchsia-custom-backdrop"
	| "green"
	| "green-custom-backdrop"
	| "cyan"
	| "cyan-custom-backdrop"
	| "purple"
	| "purple-custom-backdrop"
	| "orange-300"
	| "orange-yellow"
	| "blue-sky-light"
	| "white"
	| "yellow"
	| "green-warm-custom-backdrop"
	| "dark-blue"
	| "azure"

export enum FEATURE_GALLERY_DIRECTION {
	DEFAULT = "DEFAULT",
	REVERSE = "REVERSE",
}

interface Props {
	className?: string

	featureInner?: string

	contentWrapper?: string

	leftColumnClass?: string

	rightColumnClass?: string

	theme?: ColorTheme

	features: Array<FeatureGalleryItem>

	mediaContent?: JSX.Element

	direction?: FEATURE_GALLERY_DIRECTION

	btnStyleLink?: boolean

	buttonAction?: Function
	isMobileFeature?: boolean;
	tabsHidden?: boolean
}

export default function FeaturesGallery(props: Props) {
	const [selectedFeature, setSelectedFeature] = useState(props.features[0])
	const [currentIndex, setCurrentIndex] = useState(0);
	const localizationContext = useLocalizationContext();

	const defaultButtonText = {
		'ru-RU': "Подробнее",
		'ru-BY': "Подробнее",
		'ru-KZ': "Подробнее",
		'kz-KZ': "Толығырақ",
	}

	useEffect(() => {
		setSelectedFeature(props.features[0]);
	}, [props.features])

	const isReverse = props.direction === FEATURE_GALLERY_DIRECTION.REVERSE

	const btnStyleLink = props.btnStyleLink

	const [isModalOpen, setIsModalOpen] = useState(false)
	const containerRef = useRef(null)
	const [isPatternHidden, setIsPatternHidden] = useState(false)

	useEffect(() => {
		if (typeof selectedFeature.isPatternHidden === "boolean") {
			setIsPatternHidden(selectedFeature.isPatternHidden)
		}
	}, [selectedFeature])

	const handleModalStatus = () => {
		setIsModalOpen(!isModalOpen)
	}

	const setFeaturePosition = (featureData, evt) => {
		centerHorizontalNavItem(
			evt.currentTarget,
			evt.currentTarget.parentNode
		)

		setSelectedFeature(featureData)
	}

	const renderTabList = (className: string): JSX.Element => (
		<ul className={classNames(styles.tabsList, className)}>
			{props.features.map((feature, index) => (
				<li
					key={feature.tabName}
					className={classNames(styles.tab, {
						[styles.__selected]: feature === selectedFeature,
					})}
					onClick={e => {
						setFeaturePosition(feature, e)
						setCurrentIndex(index)
					}}
				>
					<AdaptiveWrapText
						desktop={feature.tabName}
						tablet={feature.tabNameTablet}
						mobile={feature.tabNameMobile}
						noWrap={localizationContext.locale === "kz-KZ" && true}
						noBreakWordsMobile
						className={feature?.tabClassName}
					>
						<span>{feature.tabName}</span>
					</AdaptiveWrapText>
				</li>
			))}
		</ul>
	)

	const renderThemeBackdrop = (
		feature: FeatureGalleryItem
	): JSX.Element | null => {
		switch (props.theme) {
			case "fuchsia":
				return (
					<>
						<FuchsiaBackdropShape className={styles.fuchsiaBackdropShape} />

						<div className={styles.fuchsiaBackdropPattern} />
					</>
				)

			case "green":
				return <>{feature.backdropContent}</>
			case "cyan":
				return (
					<>
						<CyanBackdropShape className={styles.cyanBackdropShape} />

						<div className={styles.cyanBackdropPattern} />
					</>
				)
			case "purple":
				return (
					<>
						<PurpleBackdropShape className={styles.purpleBackdropShape} />

						<div className={styles.purpleBackdropPattern} />
					</>
				)
			case "purple-custom-backdrop":
				return (
					<>
						<div className={styles.purpleCustomBackdrop}/>
					</>
				)

			case "cyan-custom-backdrop":
				return (
					<>
						<div className={styles.cyanCustomBackdrop} />
					</>
				)
			default:
				return null
		}
	}

	return (
		<div
			className={classNames(
				styles.wrapper,
				// Классы цветовых тем
				{
					[styles.__fuchsia]: props.theme?.includes("fuchsia"),
					[styles.__green]: props.theme?.includes("green"),
					[styles.__green_warm]: props.theme?.includes("green-warm"),
					[styles.__cyan]: props.theme?.includes("cyan"),
					[styles.__purple]: props.theme?.includes("purple"),
					[styles.__orange_300]: props.theme === "orange-300",
					[styles.__orange_yellow]: props.theme === "orange-yellow",
					[styles.__blue_sky_light]: props.theme === "blue-sky-light",
					[styles.__white]: props.theme === "white",
					[styles.__yellow]: props.theme === "yellow",
					[styles.__dark_blue_light]: props.theme === "dark-blue",
					[styles.__azure]: props.theme === "azure",
				}
			)}
		>
			<div
				ref={containerRef}
				className={classNames(styles.container, props.className, {
					[styles.containerPatternHidden]: isPatternHidden,
				}, props.features[currentIndex]?.tabContainerClass)}
			>
				<div
					className={classNames(styles.feature, props.featureInner, {
						[styles.columnReverse]: isReverse,
					})}
				>
					<article
						className={classNames(
							styles.leftColumn,
							{
								[styles.leftColumnLong]: props.features.find(
									feature => feature.isLong
								),
							},
							props.leftColumnClass
						)}
					>
						{!props.tabsHidden && renderTabList(styles.__inContainer)}

						{props.features.map(feature => (
							<div
								className={classNames(styles.leftColumnContent, {
									[styles.__selected]: feature === selectedFeature,
								}, feature?.columnStyleDesktop)}
								style={{
									width: `${feature.desktopContentWidth || 500}px`,
								}}
								key={`${feature.tabName}-content`}
							>
								{/* <h2 className={styles.featureTitle}>
                                    <AdaptiveWrapText
                                        desktop={feature.desktopTitle}
                                        tablet={feature.tabletTitle}
                                        mobile={feature.mobileTitle}
                                    />
                                </h2> */}

								<AdaptiveWrapText
									desktop={feature.desktopTitle}
									tablet={feature.tabletTitle}
									mobile={feature.mobileTitle}
									titleClasses={classNames(styles.featureTitle,
										{[styles.featureTitle__kz]: localizationContext.locale === 'kz-KZ'})}
									noBreakWordsMobile
								>
									<h2>{feature.desktopTitle}</h2>
								</AdaptiveWrapText>

								<div
									className={classNames(
										styles.featureContentWrapper,
										props.contentWrapper,
										{[mediaContent.contentWrapper__kz_crm]: localizationContext.locale !== 'ru-RU' && selectedFeature.tabName === 'CRM'}
									)}
								>
									{/* { typeof feature.desktopContent === 'string' ? (
                                        // <AdaptiveWrapText
                                        //     desktop={feature.desktopContent}
                                        //     tablet={feature.tabletContent}
                                        //     mobile={feature.mobileContent}
                                        // />
                                        <AdaptiveWrapText
                                            desktop={feature.desktopContent}
                                            tablet={feature.tabletContent}
                                            mobile={feature.mobileContent}
                                        >
                                            <span>{feature.desktopContent}</span>
                                        </AdaptiveWrapText>
                                    ) : feature.desktopContent } */}
									<AdaptiveWrapText
										desktop={feature.desktopContent}
										tablet={feature.tabletContent}
										mobile={feature.mobileContent}
									>
										<span>{feature.desktopContent}</span>
									</AdaptiveWrapText>
								</div>

								{feature.bottomContent && (
									<div className={styles.feature__bottomContent}>
										{feature.bottomContent}
									</div>
								)}
								<div className={styles.featureButtons}>
								{feature.detailLink &&
									(typeof feature.detailLink === "string" ? (
										feature.detailLink === "#popup" ? (
											<a
												className={
													!btnStyleLink
														? styles.featureDetailLink
														: styles.featureSecondDetailLink
												}
												onClick={handleModalStatus}
												href={feature.detailLink}
												rel={feature.linkRel}
												target="_blank"
											>
												{feature.detailText || "Оставить заявку"}
											</a>
										) : (
											<AdaptiveLink
												className={
													!btnStyleLink
														? styles.featureDetailLink
														: styles.featureSecondDetailLink
												}
												href={feature.detailLink}
												rel={feature.linkRel}
												target="_blank"
												useGatsbyLink={true}
											>
												{feature.detailText || defaultButtonText[localizationContext.locale]}
											</AdaptiveLink>
										)
									) : (
										<AdaptiveLink
											className={
												classNames(
													!btnStyleLink
														? styles.featureDetailLink
														: styles.featureSecondDetailLink,
													{[styles.featureDetailLink__by]: localizationContext.locale === 'ru-BY' && props.isMobileFeature}
												)
											}
											href={feature.detailLink.href}
											rel={feature.detailLink.rel}
											useGatsbyLink={feature.detailLink.useGatsbyLink}
										>
											{feature.detailText || defaultButtonText[localizationContext.locale]}
										</AdaptiveLink>
									))}

								{feature.additionalDetailLink &&
									(typeof feature.additionalDetailLink === "string" ? (
										feature.additionalDetailLink === "#popup" ? (
											<a
												className={
													!btnStyleLink
														? styles.featureDetailLink
														: styles.featureSecondDetailLink
												}
												onClick={handleModalStatus}
												href={feature.additionalDetailLink}
												rel={feature.linkRel}
												target="_blank"
											>
												{feature.additionalDetailLink || "Оставить заявку"}
											</a>
										) : (
											<AdaptiveLink
												className={
													!btnStyleLink
														? styles.featureDetailLink
														: styles.featureSecondDetailLink
												}
												href={feature.additionalDetailLink}
												rel={feature.linkRel}
												target="_blank"
												useGatsbyLink={true}
											>
												{feature.additionalDetailLink || defaultButtonText[localizationContext.locale]}
											</AdaptiveLink>
										)
									) : (
										<AdaptiveLink
											className={
												classNames(
													!btnStyleLink
														? styles.featureDetailLink
														: styles.featureSecondDetailLink,
													{[styles.featureDetailLink__by]: localizationContext.locale === 'ru-BY' && props.isMobileFeature}
												)
											}
											href={feature.additionalDetailLink.href}
											rel={feature.additionalDetailLink.rel}
											useGatsbyLink={feature.additionalDetailLink.useGatsbyLink}
											target="_blank"
										>
											{feature.additionalDetailLink.text || defaultButtonText[localizationContext.locale]}
										</AdaptiveLink>
									))}
								</div>
								{props.buttonAction && (
									<button
										onClick={props.buttonAction}
										type="button"
										className={
											!btnStyleLink
												? styles.featureDetailLink
												: styles.featureSecondDetailLink
										}
									>
										{feature.detailText || defaultButtonText[localizationContext.locale]}
									</button>
								)}
							</div>
						))}
					</article>

					{/* Если приходит отдельный mediaContent - всегда выводится он,
                     независимо от выбранной вкладки */}
					{props.mediaContent ? (
						<figure
							className={classNames(
								styles.rightColumn,
								styles.__selected,
								props.rightColumnClass
							)}
							data-selected={selectedFeature.tabName}
						>
							{props.mediaContent}
						</figure>
					) : (
						props.features.map(feature => (
							<figure
								className={classNames(
									styles.rightColumn,
									props.rightColumnClass,
									{ [styles.__selected]: feature === selectedFeature },
									{ [styles.__selectedKz]: localizationContext.locale === 'ru-KZ' && feature.tabName === 'Карты лояльности' }
								)}
								key={`${feature.tabName}-content`}
							>
								{feature.mediaContent}
							</figure>
						))
					)}
				</div>

				{renderThemeBackdrop(selectedFeature)}

				{((props.theme && props.theme.includes("custom-backdrop")) ||
					!renderThemeBackdrop(selectedFeature)) &&
					selectedFeature.backdropContent}
			</div>
			<ConsultationCtaFormA isOpen={isModalOpen} onClose={handleModalStatus} />
		</div>
	)
}
