import React, { useState, SVGProps, Dispatch, SetStateAction } from "react"
import classNames from "classnames"
import { StaticImage } from "gatsby-plugin-image"

import ConsultationCtaFormA from "../ConsultationCtaFormA"
import AppCtaForm from "../AppCtaForm"
import AdaptiveLink from "../../../components/_V2/AdaptiveLink"
import AdaptiveWrapText from "../../../components/_V2/AdaptiveWrapText"

import { PageLinkItem } from "../../../pages-data/_V2/common/links"

import styles from "./styles.module.scss"

export interface ProductPresentationBaseData {
	pageName?: string | JSX.Element
	desktopTitle: string | JSX.Element
	tabletTitle?: string | JSX.Element
	mobileTitle?: string | JSX.Element
	mobileNoBreak?: boolean
	price?: string
	subTitle: string | JSX.Element
	cta?: string
	ctaLink?: PageLinkItem
	desktopCtaDetails?: string | JSX.Element
	tabletCtaDetails?: string | JSX.Element
	mobileCtaDetails?: string | JSX.Element
	bottomCtaDetails?: string | JSX.Element
	figure: JSX.Element
	storeBadges?: {
		appstore?: StoreItem
		googleplay?: StoreItem
	}
}

interface StoreItem {
	href: string
	alt: string
}

export interface Props {
	className?: string
	data: ProductPresentationBaseData
	popupType?: string
	backdrop?: (props: SVGProps<SVGSVGElement>) => JSX.Element
	backdropMobile?: (props: SVGProps<SVGSVGElement>) => JSX.Element
	backdropClass?: string
	backdropMobileClass?: string
	containerClass?: string
	sectionClass?: string
	additionalTitleClass?: string
	additionalTabletTitleClass?: string
	additionalPriceClass?: string
	additionalDescriptionClass?: string
	additionalLeftColumn?: string
	additionalRightColumn?: string
	adaptiveWrapTextClassName?: string
}

export default function ProductPresentationBase(props: Props) {
	const data = props.data || {}
	const Backdrop = props.backdrop
	const BackdropMobile = props.backdropMobile
	const backdropClass = props.backdropClass || styles.backdrop
	const backdropMobileClass = props.backdropMobileClass || styles.backdropMobile
	const containerClass = props.containerClass || styles.content
	const sectionClass = props.sectionClass || styles.section

	const renderTitleWithPrice = () => (
		<>
			{data.desktopTitle}
			<sup className={classNames(styles.price, props.additionalPriceClass)}>
				{data.price}
			</sup>
		</>
	)

	const [isModalOpen, setIsModalOpen] = useState(false)
	const handleModalStatus = () => {
		setIsModalOpen(!isModalOpen)
	}

	return (
		<>
			<section className={classNames(sectionClass, props.className)}>
				<div className={containerClass}>
					<article
						className={classNames(
							styles.leftColumn,
							props.additionalLeftColumn
						)}
					>
						{data.pageName && (
							<h1 className={styles.pageName}>{data.pageName}</h1>
						)}
						{/* <h1 className={classNames(
                        styles.title,
                        props.additionalTitleClass,
                        { [styles.titleWithPrice]: data.price }
                    )}>
                        <AdaptiveWrapText
                            desktop={data.price ? renderTitleWithPrice() : data.desktopTitle}
                            mobile={data.mobileTitle}
                            className={props.additionalTabletTitleClass}
                        />
                    </h1> */}

						<AdaptiveWrapText
							desktop={data.price ? renderTitleWithPrice() : data.desktopTitle}
							tablet={data.tabletTitle}
							mobile={data.mobileTitle}
							className={props.additionalTabletTitleClass}
							noBreakWordsMobile={data?.mobileNoBreak}
							titleClasses={classNames(
								styles.title,
								props.additionalTitleClass,
								{
									[styles.titleWithPrice]: data.price,
								}
							)}
						>
							{!data.pageName ? (
								<h1>{data.price ? renderTitleWithPrice() : data.desktopTitle}</h1>
								) : (
								<span>{data.price ? renderTitleWithPrice() : data.desktopTitle}</span>)
							}
						</AdaptiveWrapText>

						{typeof data.subTitle === "string" ? (
							<p
								className={classNames(
									styles.subTitle,
									props.additionalDescriptionClass
								)}
							>
								{data.subTitle}
							</p>
						) : (
							<div
								className={classNames(
									styles.subTitle,
									props.additionalDescriptionClass
								)}
							>
								{data.subTitle}
							</div>
						)}

						{data.cta && data.ctaLink && (
							<div className={styles.ctaInfoWrapper}>
								<AdaptiveLink
									className={styles.ctaButton}
									href={data.ctaLink.href}
									useGatsbyLink={data.ctaLink.useGatsbyLink}
									clickHandle={handleModalStatus}
								>
									{data.cta}
								</AdaptiveLink>

								{/* <p className={styles.ctaDetails}>
                                <AdaptiveWrapText
                                    desktop={data.desktopCtaDetails}
                                    tablet={data.tabletCtaDetails}
                                    mobile={data.mobileCtaDetails}
                                />
                            </p> */}

								<AdaptiveWrapText
									desktop={data.desktopCtaDetails}
									tablet={data.tabletCtaDetails}
									mobile={data.mobileCtaDetails}
									titleClasses={styles.ctaDetails}
									className={props.adaptiveWrapTextClassName}
								>
									<p>{data.desktopCtaDetails}</p>
								</AdaptiveWrapText>
							</div>
						)}

						{data.bottomCtaDetails && (

							<AdaptiveWrapText
								desktop={data.bottomCtaDetails}
								tablet={data.bottomCtaDetails}
								mobile={data.bottomCtaDetails}
								titleClasses={styles.ctaDetailsBottom}
								className={props.adaptiveWrapTextClassName}
							>
								<p>{data.bottomCtaDetails}</p>
							</AdaptiveWrapText>
						)}

						{data.storeBadges && (
							<div className={styles.storeBadges}>
								{data.storeBadges.googleplay && (
									<a
										className={styles.storeBadge}
										href={data.storeBadges.googleplay.href}
										target={"_blank"}
									>
										<StaticImage
											src="./assets/GooglePlayBadgeDark.png"
											alt={data.storeBadges.googleplay.alt}
											quality={90}
											width={150}
										/>
									</a>
								)}

								{data.storeBadges.appstore && (
									<a
										className={styles.storeBadge}
										href={data.storeBadges.appstore.href}
										target={"_blank"}
									>
										<StaticImage
											src="./assets/AppStoreBadgeDark.png"
											alt={data.storeBadges.appstore.alt}
											quality={90}
											width={150}
										/>
									</a>
								)}
							</div>
						)}
					</article>
					<figure
						className={classNames(
							styles.rightColumn,
							props.additionalRightColumn
						)}
					>
						{data.figure}
					</figure>
					{Backdrop && <Backdrop className={backdropClass} />}
					{BackdropMobile && (
						<BackdropMobile
							className={classNames(backdropClass, backdropMobileClass)}
						/>
					)}
				</div>
			</section>

			{
				props.popupType === 'app'
					? <AppCtaForm isOpen={isModalOpen} onClose={handleModalStatus} />
					: <ConsultationCtaFormA isOpen={isModalOpen} onClose={handleModalStatus} />

			}
		</>
	)
}
