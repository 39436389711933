import React from "react"

export default {
	inputTitle: "Ваш телефон",
	modal: {
		title: (
			<span>
				Заказать
				<br />
				приложение
			</span>
		),
		action: "Оставить заявку",
		successTitle: "Отлично!",
		successDetails: "Наш менеджер перезвонит вам",
		successAction: "Хорошо",
	},
	withName: true,
	nameTitle: "Ваше имя",
}
