import * as React from "react"

const PurpleBackdropShape = (props: React.SVGProps<SVGSVGElement>) => (
	<svg
		width={661}
		height={597}
		viewBox="0 0 661 597"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		xmlnsXlink="http://www.w3.org/1999/xlink"
		{...props}
	>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M23.5431 0H631C647.569 0 661 13.4315 661 30V567C661 583.569 647.569 597 631 597H205.208C76.0305 521.064 -52.4007 277.743 23.5431 0Z"
			fill="url(#PurpleBackdropShape_linearGradient)"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M23.5431 0H631C647.569 0 661 13.4315 661 30V567C661 583.569 647.569 597 631 597H205.208C76.0305 521.064 -52.4007 277.743 23.5431 0Z"
			fill="url(#PurpleBackdropShape_dotPatern)"
			fillOpacity={0.5}
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M23.5431 0H631C647.569 0 661 13.4315 661 30V567C661 583.569 647.569 597 631 597H205.208C76.0305 521.064 -52.4007 277.743 23.5431 0Z"
			fill="url(#paint1_linear_546_15720)"
		/>
		<defs>
			<pattern
				id="PurpleBackdropShape_dotPatern"
				patternContentUnits="objectBoundingBox"
				width={0.030296}
				height={0.0343384}
			>
				<use
					xlinkHref="#PurpleBackdropShape_dotImage"
					transform="scale(0.000757401)"
				/>
			</pattern>
			<linearGradient
				id="PurpleBackdropShape_linearGradient"
				x1={639.448}
				y1={4.00023}
				x2={272.063}
				y2={554.752}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#609FFF" />
				<stop offset={0.5} stopColor="#7175F2" />
				<stop offset={1} stopColor="#A26AEB" />
			</linearGradient>
			<linearGradient
				id="paint1_linear_546_15720"
				x1={639.448}
				y1={4.00023}
				x2={272.063}
				y2={554.752}
				gradientUnits="userSpaceOnUse"
			>
				<stop stopColor="#609FFF" stopOpacity={0.76} />
				<stop offset={0.5} stopColor="#7175F2" stopOpacity={0.53} />
				<stop offset={1} stopColor="#A26AEB" stopOpacity={0.38} />
			</linearGradient>
			<image
				id="PurpleBackdropShape_dotImage"
				width={40}
				height={41}
				xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAApCAYAAABHomvIAAAAf0lEQVRYCe3SwQnAIBBE0ek1ahuxg9iLbFKWJSRIPAi5eBIhX1jw4OL4XImFAAIIIIAAAggggAACiwgEXbuTlVpB57FIrDdGDeRld19BFpcJ6WWlD9f2hYCjAk4Wv4I5jfZPObcpp/bVxSmvM39TXs8lCCCAAAIIIIAAAr8UeAAiHz7ihAEDdwAAAABJRU5ErkJggg=="
			/>
		</defs>
	</svg>
)

export default PurpleBackdropShape
